// routesConfig.js
import LoginForm from "../pages/login";
import RegisterForm from "../pages/register";
import ApplicationForm from "../pages/editTable/index";
import Index from "../pages/index";

import Contract from "../pages/contract";
import ForgotPassword from "../pages/forgotPassword";

const routesConfig = [
  {
    path: "/",
    element: <Index />,
    protected: true, // 受保护页面
  },
  {
    path: "/login",
    element: <LoginForm />,
    protected: false, // 公共页面
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    protected: false, // 公共页面
  },
  {
    path: "/register",
    element: <RegisterForm />,
    protected: false, // 公共页面
  },
  {
    path: "/application-form",
    element: <ApplicationForm />,
    protected: true, // 受保护页面
  },
  {
    path: "/contract",
    element: <Contract />,
    protected: true, // 受保护页面
  },

  {
    path: "/index",
    element: <Index />,
    protected: true, // 受保护页面
  },
];

export default routesConfig;
// 解决 TS1208 错误
export {};

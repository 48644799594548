import React from "react";
import { Form, Input, InputNumber, Select, Button, Row, Col } from "antd";
const { TextArea } = Input;

const TeamInfoStep: React.FC<{ form: any }> = ({ form }) => (
  <div>
    <h3>项目负责人信息</h3>
    <Form.Item
      label="负责人姓名"
      name="leader_name"
      rules={[{ required: false, message: "请输入负责人姓名" }]}
    >
      <Input placeholder="请输入负责人姓名" />
    </Form.Item>
    <Form.Item
      label="性别"
      name="leader_gender"
      rules={[{ required: true, message: "请选择性别" }]}
    >
      <Select placeholder="请选择性别">
        <Select.Option value="男">男</Select.Option>
        <Select.Option value="女">女</Select.Option>
      </Select>
    </Form.Item>
    <Form.Item
      label="年龄"
      name="leader_age"
      rules={[{ required: true, message: "请输入年龄" }]}
    >
      <InputNumber
        min={18}
        max={100}
        placeholder="请输入年龄"
        style={{ width: "100%" }}
      />
    </Form.Item>
    <Form.Item
      label="职务"
      name="leader_position"
      rules={[{ required: true, message: "请输入职务" }]}
    >
      <Input placeholder="请输入职务" />
    </Form.Item>
    <Form.Item
      label="职称"
      name="leader_title"
      rules={[{ required: true, message: "请输入职称" }]}
    >
      <Input placeholder="请输入职称" />
    </Form.Item>
    <Form.Item
      label="电子邮箱"
      name="leader_email"
      rules={[{ required: true, message: "请输入电子邮箱" }]}
    >
      <Input placeholder="请输入电子邮箱" />
    </Form.Item>
    <Form.Item
      label="手机"
      name="leader_contact_number"
      rules={[{ required: true, message: "请输入联系电话" }]}
    >
      <Input placeholder="请输入联系电话" />
    </Form.Item>
    <Form.Item
      label="联系地址"
      name="leader_address"
      rules={[{ required: true, message: "请输入联系地址" }]}
    >
      <Input placeholder="请输入联系地址" />
    </Form.Item>

    <h3>项目组成员信息</h3>

    <Form.List name="team_members">
      {(fields, { add, remove }) => (
        <div>
          <Row gutter={16}>
            <Col span={4}>成员姓名</Col>
            <Col span={3}>性别</Col>
            <Col span={3}>年龄</Col>
            <Col span={4}>职务</Col>
            <Col span={4}>职称</Col>
            <Col span={4}>所在单位</Col>
            <Col span={2}>操作</Col>
          </Row>
          {fields.map((field, index) => (
            <Row gutter={16} key={field.key}>
              <Col span={4}>
                <Form.Item
                  name={[field.name, "name"]}
                  rules={[{ required: true, message: "请输入成员姓名" }]}
                >
                  <Input size="small" placeholder="成员姓名" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  name={[field.name, "gender"]}
                  rules={[{ required: false, message: "请选择性别" }]}
                >
                  <Select size="small" placeholder="性别">
                    <Select.Option value="男">男</Select.Option>
                    <Select.Option value="女">女</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  name={[field.name, "age"]}
                  rules={[{ required: false, message: "请输入年龄" }]}
                >
                  <InputNumber
                    min={18}
                    max={100}
                    size="small"
                    placeholder="年龄"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name={[field.name, "position"]}
                  rules={[{ required: false, message: "请输入职务" }]}
                >
                  <Input size="small" placeholder="职务" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name={[field.name, "title"]}
                  rules={[{ required: false, message: "请输入职称" }]}
                >
                  <Input size="small" placeholder="职称" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name={[field.name, "organization"]}
                  rules={[{ required: false, message: "请输入所在单位" }]}
                >
                  <Input size="small" placeholder="所在单位" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button
                  type="link"
                  onClick={() => remove(field.name)}
                  style={{ marginTop: 4 }}
                  size="small"
                >
                  删除
                </Button>
              </Col>
            </Row>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: "100%" }}
            >
              添加成员
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>

    <Form.Item
      label="项目简介(限800字）"
      name="project_description"
      rules={[{ required: true, message: "请输入项目简介" }]}
    >
      <TextArea rows={4} maxLength={800} placeholder="请输入项目简介" />
    </Form.Item>
  </div>
);

export default TeamInfoStep;

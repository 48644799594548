import React, { useEffect } from "react";
import { Card, Form, Radio, Typography, Input } from "antd";

const { Title, Paragraph } = Typography;

const ConfirmationStep: React.FC<{ form: any }> = ({ form }) => {
  useEffect(() => {
    console.log("R哈哈哈哈哈p:", form);
  }, [form]);

  return (
    <Card
      className="confirmation-card"
      bordered={false}
      style={{ marginBottom: 24 }}
    >
      <Title level={3}>申请单位承诺</Title>
      <Paragraph>
        本单位承诺对本申请表及其他附件上填写的内容的真实性负责，并遵守上海科普教育发展基金会公益科普项目资助的相关规定。如获得项目资助，本单位承诺以本表为有约束力的协议附件。若提供的内容和材料信息不实，本单位愿意承担相应责任与后果。
      </Paragraph>
      <Form.Item
        name="confirmation"
        rules={[{ required: true, message: "请确认承诺" }]}
      >
        {/* <Input placeholder="测试" /> */}
        <Radio.Group>
          <Radio value={true}>我已阅读并承诺</Radio>
        </Radio.Group>
      </Form.Item>
    </Card>
  );
};

export default ConfirmationStep;

// authHelpers.js
import { message } from "antd";

export const handleUnauthorized = () => {
  message.error("未授权，请重新登录。");
  sessionStorage.removeItem("token"); // 清除 token
  window.location.href = "#/login"; // 跳转到登录页面
};

export const isAuthenticated = () => {
  return !!sessionStorage.getItem("token");
};

import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Upload, message, Form } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons"; // 引入图标
import { useNavigate } from "react-router-dom";
import "./index.css";
import { editApply, getApply, downloadApply } from "../../apis/api";

import { EditOutlined } from "@ant-design/icons";

const HomePage: React.FC = () => {
  const [applyId, setApplyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFileSuccess, setUploadFileSuccess] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const getDownloadUrl = async (apply_id: string | number) => {
    setLoading(true);
    const res = await downloadApply(apply_id);
    setLoading(false);
    if (res.status) {
      window.open(res.data.url, "_blank");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getApply();
        if (response.status && response.data) {
          const data = response.data;
          setApplyId(data.id);
          setStatus(data.status);

          console.log("data.contract====>", data.contract);

          // 判断盖章文件是否上传
          if (data.contract) {
            const contract = JSON.parse(data.contract);
            if (contract.project_sealed_document) {
              setUploadFileSuccess(true);
            }
          }

          if (data.status === -1) {
            setRejectionReason(data.rejectionReason);
          }
        }
      } catch (error) {
        // 处理错误
      }
    };

    fetchData();
  }, []);

  const handleUploadChange = (info: any) => {
    setFileList(info.fileList);
    if (info.file.name) {
      setUploadSuccess(true);
      form.setFieldsValue({ file: info.fileList });
    }
  };

  const handleConfirmSubmit = async (values: any) => {
    const formData = new FormData();
    if (values.file && values.file.length > 0) {
      values.file.forEach((file: any) => {
        if (file.originFileObj) {
          formData.append("file", file.originFileObj);
        }
      });
    } else {
      console.error("未找到任何上传的文件");
      return;
    }

    try {
      const res = await editApply({
        applyId: applyId,
        contract: formData,
      });
      if (res.status) {
        message.success("文件提交成功");
        setFileList([]);
        setUploadFileSuccess(true);
        form.resetFields();
      } else {
        message.error("文件提交失败");
      }
    } catch (error) {
      message.error("文件提交时发生错误");
    }
  };

  const handleReUpload = () => {
    setFileList([]);
    setUploadSuccess(false);
    form.resetFields();
  };

  const handleReapply = () => {
    // setStatus(0);
    // setRejectionReason("");
    setUploadFileSuccess(false);
    setFileList([]);
    form.resetFields();
    handleNavigate("/application-form?cate=edit&applyId=" + applyId);
  };

  return (
    <div className="homepage-container">
      <Row gutter={[16, 16]}>
        {status === 0 && (
          <Col xs={24} sm={12} md={8}>
            <Card
              title="第一步: 填写项目申请表"
              bordered={false}
              className="homepage-card"
            >
              <p>在此填写项目申请表单。</p>
              <Button
                type="primary"
                onClick={() =>
                  handleNavigate(
                    "/application-form?cate=edit&applyId=" + applyId
                  )
                }
              >
                进入表单
              </Button>
            </Card>
          </Col>
        )}
        {status === 1 && (
          <Col xs={24} sm={12} md={8}>
            <Card
              title="第一步: 填写项目申请表"
              bordered={false}
              className="homepage-card"
            >
              <p>您已填写,可以导出您的项目申请表单。</p>
              <Button
                loading={loading}
                type="primary"
                onClick={() => getDownloadUrl(applyId)}
              >
                导出文件
              </Button>
            </Card>
          </Col>
        )}
        {status === -1 && (
          <Col xs={24} sm={12} md={8}>
            <Card
              title="申请被退回"
              bordered={false}
              className="homepage-card"
              style={{ borderColor: "red" }}
            >
              <div
                style={{
                  color: "red",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ExclamationCircleOutlined
                  style={{ marginRight: 8, fontSize: "20px", color: "red" }}
                />
                <p>您的申请已被退回</p>
              </div>
              {/* <p style={{ color: 'red' }}>原因如下：</p>
              <p style={{ color: 'red' }}>{rejectionReason}</p> */}
              <Button
                type="primary"
                onClick={handleReapply}
                className="firstBtn"
              >
                重新填写申请
              </Button>
            </Card>
          </Col>
        )}
        <Col xs={24} sm={12} md={8}>
          <Card
            title="第二步：上传盖章文件"
            bordered={false}
            className="homepage-card"
          >
            {/* {!uploadFileSuccess && <p>上传申请书签字、盖章后的扫描件。</p>}
            {uploadFileSuccess && <p>您的申请已经完成提交,谢谢</p>} */}

            {uploadFileSuccess && (
              <div>
                <p>您的文件已经完成提交,谢谢</p>

                <Button
                  type="primary"
                  onClick={() => handleNavigate("/contract?applyId=" + applyId)}
                >
                  重新提交
                </Button>
              </div>
            )}
            {!uploadFileSuccess && (
              <div>
                <p>上传申请书签字、盖章后的扫描件</p>
                <Button
                  type="primary"
                  onClick={() => handleNavigate("/contract?applyId=" + applyId)}
                >
                  提交文件
                </Button>
              </div>
            )}
          </Card>
        </Col>

        <Col xs={24}>
          <Card
            title="填写说明"
            bordered={false}
            style={{ textAlign: "left" }}
            className="homepage-card"
          >
            <div style={{ textAlign: "left" }}>
              <p>
                1. 填写前准备：请先登录本基金会官网（
                <a
                  href="http://www.ssedf.org.cn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.ssedf.org.cn
                </a>
                ）或微信公众号，仔细阅读《上海科普教育发展基金会科普公益项目资助管理办法》及《2025年度科普公益项目资助申请通知》中的相关要求。请确保申请材料按要求完整填写，并附有签字、盖章及相关附件。如因材料不全、签章缺失或表格填写不完整等原因导致申请未通过审核，责任由申请人自行承担。逾期提交的申请材料将不予受理。
              </p>
              <p>
                2.
                在线填写与提交：项目申请人请登录“上海科普教育发展基金会科普公益项目资助填报系统”（
                <a
                  href="http://kpzzxm.ssedf.org.cn/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://kpzzxm.ssedf.org.cn/
                </a>
                ），注册并在线填写项目申请书。完成上传申请书签字、盖章后的扫描件，无需另行邮寄纸质版。
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HomePage;

import axios from "axios";
import { message } from "antd";
import { baseApiUrl } from "../config";

// Create axios instance with default settings
const apiClient = axios.create({
  // baseURL: "https://shkp-dev-api-server.wawoai.com/user-apis",
  baseURL: baseApiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to handle errors globally
const handleError = (error: any) => {
  const status = error.response?.status;
  const errorMessage =
    error.response?.data?.message || "发生错误，请稍后再试。";

  switch (status) {
    case 401:
      message.error("未授权，请重新登录。");
      // 跳转 登录
      window.location.href = "#/login";
      break;
    case 500:
      message.error("服务器错误，请稍后再试。");
      break;
    default:
      message.error(errorMessage);
  }
  return Promise.reject(error);
};
apiClient.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle responses globally
apiClient.interceptors.response.use(
  (response) => response.data,
  (error) => handleError(error)
);

// General function to handle requests with consistent error handling
const makeRequest = async (apiCall: any) => {
  try {
    return await apiCall();
  } catch (error) {
    // Errors are already handled globally in interceptor
    console.error("API call error: ", error);
    throw error;
  }
};

// API functions
export const login = (email: string, password: string) => {
  return makeRequest(() => apiClient.post("/login", { email, password }));
};

export const register = (email: string, password: string, code: string) => {
  return makeRequest(() =>
    apiClient.post("/register", { email, password, code })
  );
};

export const submitApplication = (data: any) => {
  return makeRequest(() => apiClient.post("/application", data));
};

export const sendRegisterVerCode = (email: string) => {
  return makeRequest(() =>
    apiClient.post("/register-verification-code", { email })
  );
};

export const sendForgotPasswordCode = (email: string) => {
  return makeRequest(() =>
    apiClient.post("/reset-password-verification-code", { email })
  );
};

export const updateUserPassword = (
  email: string,
  password: string,
  code: string
) => {
  return makeRequest(() =>
    apiClient.post("/reset-password", { email, password, code })
  );
};

export const editApply = (data: any) => {
  return makeRequest(() =>
    apiClient.post("/submit-application", { data: data })
  );
};

export const getApply = (applyId = "" as string | number | null) => {
  return makeRequest(() =>
    apiClient.get("/get-application?apply_id=" + applyId)
  );
};

export const downloadApply = (applyId: string | number) => {
  return makeRequest(() =>
    apiClient.get(`/download-application?apply_id=${applyId}`)
  );
};

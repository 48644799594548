let apiUrl = "http://localhost:3002/user-apis";
console.log("process.env.NODE_ENV_REACT============>", process.env);
if (process.env.REACT_APP_NODE_ENV === "development") {
  apiUrl = "https://shkp-dev-api-server.wawoai.com/user-apis";
}

if (process.env.REACT_APP_NODE_ENV === "production") {
  apiUrl = "https://kpzzxm-api.ssedf.org.cn/user-apis";
}
if (process.env.NODE_ENV === "development") {
  apiUrl = "http://localhost:3002/user-apis";
}
console.log('apiUrl', apiUrl)
export const baseApiUrl = apiUrl;

import React from "react";
import { Form, Table, InputNumber, Input, Button, Space, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const FundingUsageStep: React.FC<{ form: any }> = ({ form }) => {
  interface ExpenseRecord {
    key: number;
    expense_name: string;
    requested_amount: number;
    self_funded_amount: number;
    calculation_basis: string;
  }

  const columns = (remove: (name: number) => void) => [
    {
      title: "费用名称",
      dataIndex: "expense_name",
      key: "expense_name",
      render: (_: any, record: ExpenseRecord, index: number) => (
        <Form.Item
          name={[index, "expense_name"]}
          rules={[{ required: true, message: "请输入费用名称" }]}
          style={{ marginBottom: 0 }}
        >
          <Input placeholder="请输入费用名称" style={{ padding: "4px 8px" }} />
        </Form.Item>
      ),
    },
    {
      title: "申请资助金额（万元）",
      dataIndex: "requested_amount",
      key: "requested_amount",
      width: 150,
      render: (_: any, record: ExpenseRecord, index: number) => (
        <Form.Item
          name={[index, "requested_amount"]}
          rules={[{ required: true, message: "请输入申请资助金额" }]}
          style={{ marginBottom: 0 }}
        >
          <InputNumber
            min={0}
            size="small"
            step={0.1}
            placeholder="请输入申请资助金额"
            style={{ width: "100%", padding: "4px 8px" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "自筹配套金额（万元）",
      dataIndex: "self_funded_amount",
      key: "self_funded_amount",
      width: 150,
      render: (_: any, record: ExpenseRecord, index: number) => (
        <Form.Item
          name={[index, "self_funded_amount"]}
          rules={[{ required: true, message: "请输入自筹配套金额" }]}
          style={{ marginBottom: 0 }}
        >
          <InputNumber
            min={0}
            size="small"
            step={0.1}
            placeholder="请输入自筹配套金额"
            style={{ width: "100%", padding: "4px 8px" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "计算依据",
      dataIndex: "calculation_basis",
      key: "calculation_basis",
      render: (_: any, record: ExpenseRecord, index: number) => (
        <Form.Item
          name={[index, "calculation_basis"]}
          rules={[{ required: true, message: "请输入计算依据" }]}
          style={{ marginBottom: 0 }}
        >
          <TextArea
            rows={2}
            placeholder="请输入计算依据"
            style={{ padding: "4px 8px" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "操作",
      key: "action",
      render: (_: any, record: ExpenseRecord, index: number) => (
        <MinusCircleOutlined
          onClick={() => {
            remove(index);
            message.success("删除成功！");
          }}
          style={{ color: "red", marginLeft: 8, cursor: "pointer" }}
        />
      ),
    },
  ];

  const defaultValues: ExpenseRecord[] = [
    {
      key: 0,
      expense_name: "材料费",
      requested_amount: 0,
      self_funded_amount: 0,
      calculation_basis: "",
    },
    {
      key: 1,
      expense_name: "测试化验加工费",
      requested_amount: 0,
      self_funded_amount: 0,
      calculation_basis: "",
    },
    {
      key: 2,
      expense_name: "会议费",
      requested_amount: 0,
      self_funded_amount: 0,
      calculation_basis: "",
    },
    {
      key: 3,
      expense_name: "差旅费",
      requested_amount: 0,
      self_funded_amount: 0,
      calculation_basis: "",
    },
    {
      key: 4,
      expense_name: "出版/文献/信息传播/知识产权事务费",
      requested_amount: 0,
      self_funded_amount: 0,
      calculation_basis: "",
    },
    {
      key: 5,
      expense_name: "劳务费",
      requested_amount: 0,
      self_funded_amount: 0,
      calculation_basis: "",
    },
    {
      key: 6,
      expense_name: "专家费",
      requested_amount: 0,
      self_funded_amount: 0,
      calculation_basis: "",
    },
    {
      key: 7,
      expense_name: "其他费用",
      requested_amount: 0,
      self_funded_amount: 0,
      calculation_basis: "",
    },
  ];

  return (
    <div>
      <h3>申请资助金额</h3>
      <Form.Item
        label="项目预算总金额（万元）"
        name="total_budget"
        rules={[{ required: true, message: "请输入项目预算总金额" }]}
      >
        <InputNumber
          min={0}
          step={0.1}
          placeholder="请输入项目预算总金额"
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label="自筹配套金额（万元）"
        name="self_funded_amount"
        rules={[{ required: true, message: "请输入自筹配套金额" }]}
      >
        <InputNumber
          min={0}
          step={0.1}
          placeholder="请输入自筹配套金额"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        label="申请资助金额（万元）"
        name="apply_for_amount"
        rules={[{ required: true, message: "请输入申请资助金额" }]}
      >
        <InputNumber
          min={0}
          step={0.1}
          placeholder="请输入申请资助金额"
          style={{ width: "100%" }}
        />
      </Form.Item>
      <h3>资金用途</h3>
      <p style={{ color: "red" }}>
        注：劳务费一般控制在资助经费的20%以内，固定资产不列入资助资金范围。
      </p>

      <Form.List name="expenses" initialValue={defaultValues}>
        {(fields, { add, remove }) => (
          <>
            <Table
              columns={columns(remove)}
              dataSource={fields.map((field) => ({
                key: field.key,
                ...form.getFieldValue(["expenses", field.name]),
              }))}
              pagination={false}
              rowKey="key"
              style={{ marginBottom: 0 }}
              rowClassName={() => "editable-row"}
            />
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "100%", marginTop: 16 }}
                icon={<PlusOutlined />}
              >
                添加类目
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default FundingUsageStep;

import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Radio, Button, message, Upload } from "antd";
import { baseApiUrl } from "../../../config";
import type { UploadProps } from "antd";
const { TextArea } = Input;

const ProjectDataStep: React.FC<{ form: any }> = ({ form }) => {
  const [props, setProps] = useState<UploadProps>({
    name: "file",
    action: `${baseApiUrl}/upload-file`,
    headers: {},
    // onChange(info: any) {
    //   console.log("info===>", info);
    //   if (info.file.status !== "uploading") {
    //     console.log(info.file, info.fileList);
    //   }
    //   if (info.file.status === "done") {
    //     const response = info.file.response;

    //     console.log("response===>", response);
    //     if (response.status) {
    //       const uploadedUrl = response.data.url; // 假设服务器返回的数据包含文件的 URL

    //       const currentAttachments =
    //         form.getFieldValue("project_reason_attachment") || [];
    //       form.setFieldsValue({
    //         unitIntroduction_attachment: [...currentAttachments, uploadedUrl],
    //       });
    //     }
    //     // 上传成功，获得文件的 URL
    //     // if (uploadedUrl) {
    //     //   message.success(`${info.file.name} 文件上传成功`);
    //     //   // 更新表单字段，将 URL 存储到 `unitIntroduction_attachment` 中
    //     //   form.setFieldsValue({
    //     //     unitIntroduction_attachment: uploadedUrl,
    //     //   });
    //     // }
    //   } else if (info.file.status === "error") {
    //     message.error(`${info.file.name} 文件上传失败`);
    //   }
    // },
  });

  const handleUploadChange = (info: any, fieldName: string) => {
    if (info.file.status === "done") {
      const response = info.file.response;
      console.log("fieldName===>", fieldName);

      if (response.status) {
        const uploadedUrl = response.data.url;
        form.setFieldsValue({
          [fieldName]: [
            {
              uid: response.data.fileId,
              name: info.file.name,
              status: "done",
              url: uploadedUrl,
            },
          ],
        });
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} 文件上传失败`);
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      setProps({
        ...props,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, []);

  return (
    <div>
      <Form.Item
        label="项目申请理由与项目内容（请注明项目意义及需求分析、国内外发展状况、科普价值及创新点、受益对象、项目内容等。）（限3000字）"
        name="project_reason"
        rules={[
          {
            required: true,
            message:
              "项目申请理由与项目内容（请注明项目意义及需求分析、国内外发展状况、科普价值及创新点、受益对象、项目内容等。）（限3000字）",
          },
        ]}
      >
        <TextArea
          rows={4}
          maxLength={3000}
          placeholder="请注明项目意义及需求分析、国内外发展状况、科普价值及创新点、受益对象、项目内容等。"
        />
      </Form.Item>
      <Form.Item
        label="如有附件,请上传"
        name="project_reason_attachment"
        layout="horizontal"
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload
          {...props}
          onChange={(info) =>
            handleUploadChange(info, "project_reason_attachment")
          }
        >
          <Button type="link"> 上传文件</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        label="项目实施计划与预期目标（请注明项目实施时间、地点、内容、进度安排及考核指标）"
        name="plan_target"
        rules={[{ required: true, message: "请输入项目申请理由与内容" }]}
      >
        <TextArea
          rows={4}
          placeholder="请注明项目实施时间、地点、内容、进度安排及考核指标（预期目标）"
        />
      </Form.Item>
    </div>
  );
};
export default ProjectDataStep;

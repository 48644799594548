import React, { useState, useEffect } from "react";
import { Form, Button, Steps, message, Modal, notification } from "antd";
import ConfirmationStep from "./steps/ConfirmationStep";
import ProjectInfoStep from "./steps/ProjectInfoStep";
import TeamInfoStep from "./steps/TeamInfoStep";
import ProjectDataStep from "./steps/ProjectDataStep";
import FundingUsageStep from "./steps/FundingUsageStep";
import { editApply, getApply, downloadApply } from "../../apis/api";
import { useLocation } from "react-router-dom";
import "./index.css";

const { Step } = Steps;

const FundingApplicationForm: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [applyId, setApplyId] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [api, contextHolder] = notification.useNotification();

  const steps = [
    {
      title: "确认承诺",
      key: "confirmation",
      content: <ConfirmationStep form={form} />,
    },
    {
      title: "项目信息",
      key: "projectInfo",
      content: <ProjectInfoStep form={form} />,
    },
    {
      title: "项目组信息",
      key: "teamInfo",
      content: <TeamInfoStep form={form} />,
    },
    {
      title: "项目资料",
      key: "projectData",
      content: <ProjectDataStep form={form} />,
    },
    {
      title: "项目资金",
      key: "fundingUsage",
      content: <FundingUsageStep form={form} />,
    },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const applyId = queryParams.get("applyId");
    console.log("路径applyId====>", applyId);

    // 模拟从接口获取数据
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getApply(applyId);
        if (response.status && response.data) {
          const data = response.data;

          setApplyId(data.id);

          // 根据步骤的 key 字段赋值
          const newData = {} as any;
          steps.forEach((step) => {
            if (data[step.key]) {
              const tmp = JSON.parse(data[step.key]);
              for (const key in tmp) {
                newData[key] = tmp[key];
              }
            }
          });

          form.setFieldsValue(newData);
          // 强制刷新组件
          // form.validateFields();
        }
      } catch (error) {
        message.error("获取数据失败");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [form]);

  const handleNext = async (status = 0) => {
    try {
      const values = await form.validateFields();
      // 获取当前步骤的 key 字段
      const currentStepKey = steps[currentStep].key;

      console.log("values", values);
      console.log("currentStepKey==>", {
        applyId: applyId,
        status: status,
        [currentStepKey]: values,
      });

      const res = await editApply({
        applyId: applyId,
        status: status,
        [currentStepKey]: values,
      });
      if (res.status) {
        if (currentStep >= steps.length - 1) {
          return res;
        }
        // 模拟保存数据到接口
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        message.error(res.message || "保存失败");
      }
      return res;
    } catch (error) {
      // 验证未通过，不做处理，Form 会自动提示
    }
    return null;
  };

  const handlePrev = () => {
    console.log("handlePrev", currentStep);
    if (currentStep >= steps.length) {
      return;
    }

    setCurrentStep((prevStep) => prevStep - 1);
  };

  const getDownloadUrl = async (apply_id: number) => {
    const res = await downloadApply(apply_id);
    if (res.status) {
      window.open(res.data.url, "_blank");
    }

    // window.open("/download-application-form", "_blank")
  };

  const saveForm = async () => {
    const response = await handleNext();
    if (!response) {
      return;
    }

    if (response.status) {
      message.success("保存成功");
    }
  };

  const handleSubmit = async () => {
    Modal.confirm({
      title: "确认提交申请",
      content: "提交审核后不可以修改，确认要提交吗？",
      okButtonProps: { style: { width: "auto" } },
      cancelButtonProps: { size: "large" },
      onOk: async () => {
        const response = await handleNext(1);
        if (!response) {
          return;
        }

        if (response.status) {
          // message.success("保存成功");

          api.info({
            message: `申请已经提交。请下载打印申请表，进行盖章内容，然后上传`,
            description: "",
            placement: "topRight",
            onClose: () => {
              // 跳转到首页
              window.open("/");
            },
          });

          // Modal.success({
          //   title: "申请已提交",
          //   content: (
          //     <div>
          //       <p>申请已经提交。请下载打印申请表，进行盖章内容。</p>
          //       <Button
          //         type="primary"
          //         onClick={() => getDownloadUrl(response.data.apply_id)}
          //       >
          //         下载申请表
          //       </Button>
          //     </div>
          //   ),
          //   okButtonProps: { style: { display: "none" } },
          //   maskClosable: true, // 点击遮罩可以关闭
          //   closable: true, // 确保右上角的关闭按钮存在
          // });
        } else {
          message.error(response.message || "保存失败");
        }
      },
      okText: "确认",
      cancelText: "取消",
    });
  };

  return (
    <div className="funding-application-container">
      {contextHolder}
      <Steps current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Form form={form} layout="vertical" onFinish={() => {}}>
        <div className="steps-content">{steps[currentStep].content}</div>
        <div className="steps-action">
          {currentStep > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={handlePrev}>
              上一步
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button style={{ margin: "0 8px" }} onClick={saveForm}>
              保存
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => {
                handleNext(0);
              }}
              loading={loading}
            >
              下一步
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" onClick={handleSubmit}>
              提交申请
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default FundingApplicationForm;

import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Radio, Button, message, Upload } from "antd";
import { baseApiUrl } from "../../../config";

import type { UploadProps } from "antd";
const { TextArea } = Input;

const ProjectInfoStep: React.FC<{ form: any }> = ({ form }) => {
  const [props, setProps] = useState<UploadProps>({
    name: "file",
    action: `${baseApiUrl}/upload-file`,
    headers: {},
    // onChange(info: any) {
    //   console.log("info===>", info);
    //   if (info.file.status !== "uploading") {
    //     console.log(info.file, info.fileList);
    //   }
    //   if (info.file.status === "done") {
    //     const response = info.file.response;

    //     console.log("response===>", response);
    //     if (response.status) {
    //       const uploadedUrl = response.data.url; // 假设服务器返回的数据包含文件的 URL
    //       // 获得item name
    //       const itemName=info.file.name;

    //       const currentAttachments =form.getFieldValue("unitIntroduction_attachment") || [];
    //       form.setFieldsValue({
    //         unitIntroduction_attachment: [...currentAttachments, uploadedUrl],
    //       });
    //     }
    //     // 上传成功，获得文件的 URL
    //     // if (uploadedUrl) {
    //     //   message.success(`${info.file.name} 文件上传成功`);
    //     //   // 更新表单字段，将 URL 存储到 `unitIntroduction_attachment` 中
    //     //   form.setFieldsValue({
    //     //     unitIntroduction_attachment: uploadedUrl,
    //     //   });
    //     // }
    //   } else if (info.file.status === "error") {
    //     message.error(`${info.file.name} 文件上传失败`);
    //   }
    // },
  });

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      setProps({
        ...props,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, []);

  const handleUploadChange = (info: any, fieldName: string) => {
    if (info.file.status === "done") {
      const response = info.file.response;

      if (response.status) {
        const uploadedUrl = response.data.url;
        form.setFieldsValue({
          [fieldName]: [
            {
              uid: response.data.fileId,
              name: info.file.name,
              status: "done",
              url: uploadedUrl,
            },
          ],
        });
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} 文件上传失败`);
    }
  };

  return (
    <div>
      <Form.Item
        label="项目名称"
        name="project_name"
        rules={[{ required: true, message: "请输入项目名称" }]}
      >
        <Input placeholder="请输入项目名称" />
      </Form.Item>
      <Form.Item
        label="申请单位名称"
        name="organization_name"
        rules={[{ required: true, message: "请输入申请单位名称" }]}
      >
        <Input placeholder="请输入申请单位名称" />
      </Form.Item>
      <Form.Item
        label="单位地址"
        name="organization_address"
        rules={[{ required: true, message: "请输入单位地址" }]}
      >
        <Input placeholder="请输入单位地址" />
      </Form.Item>
      <Form.Item
        label="单位性质"
        name="organization_type"
        rules={[{ required: true, message: "请输入单位性质" }]}
      >
        <Input placeholder="请输入单位性质" />
      </Form.Item>
      <Form.Item
        label="法定代表人"
        name="organization_fr"
        rules={[{ required: true, message: "请输入法定代表人" }]}
      >
        <Input placeholder="请输入法定代表人" />
      </Form.Item>
      <Form.Item
        label="联系地址"
        name="organization_fr_address"
        rules={[{ required: true, message: "请输入联系地址" }]}
      >
        <Input placeholder="请输入联系地址" />
      </Form.Item>
      <Form.Item
        label="联系电话"
        name="fr_contact_number"
        rules={[{ required: true, message: "请输入联系电话" }]}
      >
        <Input placeholder="请输入联系电话" />
      </Form.Item>
      <Form.Item
        label="电子邮箱"
        name="fr_email"
        rules={[{ required: true, type: "email", message: "请输入电子邮箱" }]}
      >
        <Input placeholder="请输入电子邮箱" />
      </Form.Item>
      <Form.Item
        label="申请经费（单位：万元）"
        name="requested_funding"
        rules={[{ required: true, message: "请输入申请经费" }]}
      >
        <InputNumber
          min={0}
          step={0.1}
          placeholder="请输入申请经费"
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label="项目类型"
        name="project_type"
        rules={[{ required: true, message: "请选择项目类型" }]}
      >
        <Radio.Group>
          <Radio value="科普活动">科普活动</Radio>
          <Radio value="科普作品">科普作品</Radio>
          <Radio value="科普研究">科普研究</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="申请单位基本简介、相关工作经历和成绩（限500字）"
        name="unitIntroduction"
        rules={[
          {
            required: true,
            message: "请输入申请单位的基本简介、相关工作经历和成绩",
          },
        ]}
      >
        <TextArea
          rows={4}
          maxLength={500}
          placeholder="请输入申请单位的基本简介、相关工作经历和成绩"
        />
      </Form.Item>
      <Form.Item
        label="如有附件，请上传"
        name="unitIntroduction_attachment"
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
        layout="horizontal"
      >
        <Upload
          {...props}
          onChange={(info) =>
            handleUploadChange(info, "unitIntroduction_attachment")
          }
        >
          <Button type="link"> 上传文件</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="请上传申请单位法人登记证扫描件"
        name="unitLegal_person_certificate"
        valuePropName="fileList"
        rules={[
          { required: true, message: "请上传申请单位法人登记证扫描件" },
        ]}
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload
          {...props}
          onChange={(info) =>
            handleUploadChange(info, "unitLegal_person_certificate")
          }
        >
          <Button type="link"> 上传文件</Button>
        </Upload>
      </Form.Item>
    </div>
  );
};

export default ProjectInfoStep;
